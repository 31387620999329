import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Progress from "components/Progress";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "progress"
    }}>{`Progress`}</h1>

    <Playground __position={0} __code={'<>\n  <div className=\"text-center\">0%</div>\n  <Progress />\n  <div className=\"text-center\">25%</div>\n  <Progress value=\"25\" />\n  <div className=\"text-center\">50%</div>\n  <Progress value={50} />\n  <div className=\"text-center\">75%</div>\n  <Progress value={75} />\n  <div className=\"text-center\">100%</div>\n  <Progress value=\"100\" />\n  <div className=\"text-center\">Multiple bars</div>\n  <Progress multi>\n    <Progress bar value=\"15\" />\n    <Progress bar color=\"success\" value=\"30\" />\n    <Progress bar color=\"info\" value=\"25\" />\n    <Progress bar color=\"warning\" value=\"20\" />\n    <Progress bar color=\"danger\" value=\"5\" />\n  </Progress>\n</>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Progress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <div className="text-center">0%</div>
    <Progress mdxType="Progress" />
    <div className="text-center">25%</div>
    <Progress value="25" mdxType="Progress" />
    <div className="text-center">50%</div>
    <Progress value={50} mdxType="Progress" />
    <div className="text-center">75%</div>
    <Progress value={75} mdxType="Progress" />
    <div className="text-center">100%</div>
    <Progress value="100" mdxType="Progress" />
    <div className="text-center">Multiple bars</div>
    <Progress multi mdxType="Progress">
      <Progress bar value="15" mdxType="Progress" />
      <Progress bar color="success" value="30" mdxType="Progress" />
      <Progress bar color="info" value="25" mdxType="Progress" />
      <Progress bar color="warning" value="20" mdxType="Progress" />
      <Progress bar color="danger" value="5" mdxType="Progress" />
    </Progress>
  </>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/progress/" target="_blank">Reactstrap Progress</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      